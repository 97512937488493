.react-daterange-picker {
  display: inline-flex;
  position: relative;
  height: 46px;
}
.react-daterange-picker__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.react-daterange-picker__inputGroup {
  position: absolute;

  cursor: pointer;
}
.react-daterange-picker__inputGroup:first-child {
  /* border: 1px solid purple; */
  left: 16px;
  width: 49%;
  z-index: 9;
}
.react-daterange-picker__inputGroup:last-child {
  /* border: 1px solid red; */
  right: 0;
  width: 49%;
  z-index: 9;
}

#text-field-container {
  background-color: blue;
}

#editing-view-port {
  background-color: red;
}

.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.react-daterange-picker--disabled {
  background-color: #ff3636;
  color: #6d6d6d;
}
.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  padding: 0 45px 0 16px;
  border-radius: 8px;
  width: 280px;
  border: thin solid #cacbd4;
  /* background-color: red; */
  overflow: hidden;
}
.react-daterange-picker__inputGroup {
  width: 100px;
}
.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-daterange-picker__inputGroup__input {
  position: relative;
  border: 0;
  background: none;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-daterange-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-daterange-picker__button {
  border: 0;
  background: transparent;
}
.react-daterange-picker__button:enabled {
  cursor: pointer;
}
.react-daterange-picker__button:enabled:hover
  .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
  .react-daterange-picker__button__icon {
  stroke: #d70000;
}
.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
  stroke: #6d6d6d;
}
.react-daterange-picker__button svg {
  display: inherit;
}
.react-daterange-picker__calendar {
  width: 280px;
  max-width: 100vw;
  z-index: 1;
}
.react-daterange-picker__calendar--closed {
  display: none;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}
.react-calendar__month-view__days__day--weekend {
  color: black;
}
.react-calendar__tile--active {
  color: white;
}
.react-daterange-picker__calendar .react-calendar {
  border: thin solid #cacbd4;
  border-radius: 8px;
  overflow: hidden;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #115a95;
}

.react-calendar__tile--now {
  background-color: aliceblue;
}

.react-calendar__tile--active {
  background-color: #004b89;
}
